export const colors = {
  gray: {
    ultralight: '#fafafa',
    lightest: '#eee',
    lighten: '#ddd',
    light: '#bbb',
    medium: '#999',
    dark: '#777',
    darken: '#555',
    darkest: '#333',
  },
  purple: {
    lightest: '#f3e5f5',
    medium: '#d500f9',
    dark: '#8e24aa',
  },
  blue: {
    lightest: '#e8eaf6',
    medium: '#3d5afe',
    dark: '#3949ab',
  },
  cyan: {
    lightest: '#e0f7fa',
    medium: '#1cd0e6',
    dark: '#00acc1',
  },
  green: {
    lightest: '#e8f5e9',
    medium: '#00e676',
    dark: '#39b55a',
  },
  yellow: {
    lightest: '#fff8e1',
    medium: '#ffc400',
    dark: '#ffa000',
  },
  red: {
    lightest: '#ffebee',
    medium: '#ff6042',
    dark: '#e64a19',
  },
  country: {
    ar: '#338AF3',
    br: '#6DA544',
    cl: '#D80027',
    co: '#FFDA44',
    mx: '#6DA544',
    us: '#BD3D44',
  },
  insurance: {
    aig: '#9f1a65',
    alfa: '#053455',
    aliro: '#6EB9AD',
    allianz: '#013e8c',
    aseguradorasolidaria: '#ef8e00',
    atm: '#7c1338',
    axa: '#00008f',
    azul: '#002D9F',
    bci: '#00a12b',
    bolivar: '#006d38',
    bradesco: '#CC0930',
    chilenaconsolidada: '#1b0082',
    chubb: '#9a00d7',
    consorcio: '#003058',
    equidad: '#2ca02c',
    experta: '#6E97D0',
    fid: '#D43932',
    galeno: '#00a78d',
    hdi: '#229726',
    hdiPlus: '#229726',
    'hdi-plus': '#229726',
    integrity: '#042066',
    liberty: '#211645',
    mapfre: '#ff0400',
    meridional: '#5cad28',
    mercantil: '#00358e',
    mundial: '#143b4f',
    nacion: '#0268bf',
    orbis: '#0392db',
    'porto-seguro': '#5A9FF8',
    portoseguro: '#5A9FF8',
    provincia: '#033d7c',
    prudencia: '#0340c3',
    prudential: '#007AC1',
    'cruz-roja': '#ED1D24',
    reale: '#0331b9',
    renta: '#D9252D',
    rentanacional: '#D9252D',
    sancor: '#b4016d',
    sbs: '#9f1a65',
    'san-cristobal': '#00467F',
    'seguros-del-estado': '#043b82',
    sompo: '#CC0022',
    suhai: '#003E32',
    sulamerica: '#F57113',
    sura: '#02bfdd',
    tokio: '#009074',
    'tokio-marine': '#009074',
    zenit: '#ff9100',
    zurich: '#1b0082',
    'zurich-chile': '#2167ae',
    other: '#999',
    placeholder: '#999999',
  },
  social: {
    whatsapp: '#25D366',
    facebook: '#2A81EA',
    instagram: '#DE3C79',
    twitter: '#1DA1F2',
    youtube: '#FF0000',
    linkedin: '#0073B1',
    blog: '#FFA000',
  },
};
export const colorTheme = {
  mute: colors.gray.dark,
  interactive: colors.cyan.dark,
  hintFieldDisabled: colors.gray.lightest,
  hintGhostDisabled: colors.gray.ultralight,
  hintGhostHoverActive: colors.cyan.lightest,
  hintGroupingLine: colors.gray.lighten,
  hintInput: colors.gray.light,
  hintInteractive: colors.cyan.dark,
  hintInteractiveBright: colors.cyan.medium,
  hintSuccess: colors.green.dark,
  hintWarning: colors.yellow.dark,
  hintDanger: colors.red.dark,
  textPlaceholder: colors.gray.lighten,
  textLight: colors.gray.light,
  textMute: colors.gray.dark,
  textDefault: colors.gray.darkest,
};
